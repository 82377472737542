export default [
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
    },
  },

  {
    path: "/",
    name: "landing",
    meta: {title: "Landing", authRequired: false},
    component: () => import("../views/landing/index"),
  },



]
